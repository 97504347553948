import React from "react"
import Layout from "../components/layout"

const about = () => {
  return (
    <Layout>
      <h1>About bikerentalmap</h1>
      <p>
        Currently an experimental project in progress to collect and show bikerental services as well as E-Scooter rentals
        worldwide.
      </p>
      <p>
        We try to gather several sources to get information on bikerentals
        worldwide.
      </p>
      <p>Last update on June 2019</p>
      <h2>Todo</h2>
      <ul>
        <li>More direct Sources</li>
        <li>distinct marker (availability</li>
        <li>include cargo bikes</li>
        <li>improve search</li>
        <li>add filter</li>
        <li>some statistics</li>
      </ul>

      <h3>Known issues</h3>
      <ul>
        <li>in some less prominent cities there will be no bikes at the first glimps. The search is currently limited to radius of 400 meters. So it might help moving the map abit (to the center or the train station). We work on this limitation.</li>
      </ul>

      <p>Many Thanks to <a href="https://github.com/PierrickP">PierrickP</a> and his great work on <a href="https://github.com/PierrickP/multicycles/blob/master/LICENSE.md">Multicycles Project</a> gathering numerous API data.</p>
    </Layout>
  )
}

export default about
